import React, {useState, useEffect} from 'react';
import axios from 'axios';



const Subscribers=()=>{
	const [sub, setSub] = useState([]);

	useEffect(()=>{
		loadSub();
	},[]);


	const loadSub = async () =>{
		const result = await axios.get("https://posla-api.herokuapp.com/api/front/projects");
		if(result){
			console.log(result);
		}
		
	}
	return(
		<>
		<table>
			<th>Id</th>
			<th>Email</th>
			{sub.map((subx, index)=>(
				<tr>
					<td>{index}</td>
					<td>{subx.message}</td>
				</tr>
				))}

		</table>
		</>
		)
} 
export default Subscribers;