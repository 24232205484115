import React from 'react'
import Navigation from '../components/Navigation'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import data from '../components/data/info'

const Ebook = () => {
  return (
<>
    <header>
        <Navigation/>
    </header>  
    <main>
        <h3 style={{textAlign:'center'}}>Ebooks</h3>
        <h5 style={{marginTop: 100}}>Frontend Development</h5>
        <div className='ebook row'>
            {data.Ebooks.FrontendDev.map((item)=>(
                <div>
                    <img src={item.image} style={{marginBottom:10}}/>
                    <label>
                        Qty
                    </label>
                    <input type='number' style={{width:50, marginLeft: 5}}/>
                    <br/>
                    <span>Description: <span style={{fontStyle:'italic'}}>{item.description}</span></span>
                    <br/>
                    <span>Ratings:<span></span></span>
                    <br/>
                    <span>Price: <span>{item.price}</span></span>
                    <br/>
                    <button>Add To Cart</button>
                    <button>Buy Now</button>
                </div>
            ))}
        </div>
        <h5 style={{marginTop: 50}}>Full Frontend Development Package</h5>
        <div className='ebook row'>
                 <div>
                     <img src={data.Ebooks.FrontendDevFullPackage.image}/>
                     <label>
                        Qty
                    </label>
                    <input type='number' style={{width:50, marginLeft: 5}}/>
                    <br/>
                    <span>Description: <span style={{fontStyle:'italic'}}>{data.Ebooks.FrontendDevFullPackage.description}</span></span>
                    <br/>
                    <span>Ratings:<span></span></span>
                    <br/>
                    <span>Price: <span>{data.Ebooks.FrontendDevFullPackage.price}</span></span>
                    <br/>
                    <button>Add To Cart</button>
                    <button>Buy Now</button>
                 </div>
        </div>
        <h5 style={{marginTop: 100}}>Backend Development</h5>
        <div className='ebook row'>
            {data.Ebooks.BackendDev.map((item)=>(
                <div>
                    <img src={item.image}/>
                    <label>
                        Qty
                    </label>
                    <input type='number' style={{width:50, marginLeft: 5}}/>
                    <br/>
                    <span>Description: <span style={{fontStyle:'italic'}}>{item.description}</span></span>
                    <br/>
                    <span>Ratings:<span></span></span>
                    <br/>
                    <span>Price: <span>{item.price}</span></span>
                    <br/>
                    <button>Add To Cart</button>
                    <button>Buy Now</button>
                </div>
            ))}
        </div>
        <h5 style={{marginTop: 50}}>Full Backend Development Package</h5>
        <div className='ebook row'>
            <div>
                <img src={data.Ebooks.BackendDevFullPackage.image}/>
                <label>
                        Qty
                </label>
                <input type='number' style={{width:50, marginLeft: 5}}/>
                <br/>
                <span>Description: <span style={{fontStyle:'italic'}}>{data.Ebooks.BackendDevFullPackage.description}</span></span>
                <br/>
                <span>Ratings:<span></span></span>
                <br/>
                <span>Price: <span>{data.Ebooks.BackendDevFullPackage.price}</span></span>
                <br/>
                <button>Add To Cart</button>
                <button>Buy Now</button>
            </div>
        </div>
        <h5 style={{marginTop: 100}}>Git & Github</h5>
        <div className='ebook-adj row'>
            {data.Ebooks.Git.map((item)=>(
                <div>
                    <img src={item.image}/>
                    <label>
                        Qty
                    </label>
                    <input type='number' style={{width:50, marginLeft: 5}}/>
                    <br/>
                    <span>Description: <span style={{fontStyle:'italic'}}>{item.description}</span></span>
                    <br/>
                    <span>Ratings:<span></span></span>
                    <br/>
                    <span>Price: <span>{item.price}</span></span>
                    <br/>
                    <button>Add To Cart</button>
                    <button>Buy Now</button>
                </div>
            ))}
        </div>
    </main>  
    <Contact />    
    <Footer/>
</>
    
  )
}

export default Ebook