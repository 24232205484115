import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';

const Join = () =>{
	return(
	<>
		<header>
			<Navigation/>
		</header>
		<div style={{width:"100%", height: "400px", display: "grid"}} className="cohort">
			<Link to="/#Whatsapp" onClick={()=>{window.location.href = 'https://wa.me/+2348123888508'}} style={{margin: "auto"}}><button style={{border: 0, borderRadius: "5px", height: "3rem", width: "10rem", fontSize:"1rem", color: "#fff", cursor:"pointer", backgroundColor:"rgb(0,0,128)"}}>Join the Cohort</button></Link>
		</div>
		<Footer/>
	</>
		)
}
export default Join;