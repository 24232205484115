import React from 'react';
import {BrowserRouter, Link, Routes, Outlet, Route} from "react-router-dom";
import Home from './pages/Home';
import FrontendCurriculum from './pages/FrontendCurriculum';
import FrontendTutor from './pages/FrontendTutor';
import BackendCurriculum from './pages/BackendCurriculum';
import BackendTutor from './pages/BackendTutor';
import MobileCurriculum from './pages/MobileCurriculum';
import MobileTutor from './pages/MobileTutor';
import DataCurriculum from './pages/DataCurriculum';
import DataTutor from './pages/DataTutor';
import EnrollForm from './pages/EnrollForm';
import EnrollForm1 from './pages/EnrollForm1';
import EnrollForm2 from './pages/EnrollForm2';
import EnrollForm3 from './pages/EnrollForm3';
import Join from './pages/Join';
import Subscribers from './pages/Subscribers';
import Messages from './pages/Messages';
import Paid from './pages/Paid';
import Ebook from './pages/Ebook';




const App = () => {

  return(
  <>
  <BrowserRouter>
       <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Frontend_Dev_Curriculum/" element={<FrontendCurriculum/>}/>
        {/*<Route path="/Frontend_Dev_Tutor" element={<FrontendTutor/>}/>*/}
        <Route path="/Backend_Dev_Curriculum" element={<BackendCurriculum/>}/>
        {/*<Route path="/Backend_Tutor" element={<BackendTutor/>}/>*/}
        <Route path="/Mobile_App_Curriculum" element={<MobileCurriculum/>}/>
        {/*<Route path="/Mobile_App_Tutor" element={<MobileTutor/>}/>*/}
        <Route path="//DataScience_Curriculum" element={<DataCurriculum/>}/>
        {/*<Route path="/DataScience_Tutor" element={<DataTutor/>}/>*/}
        <Route path="/Enroll_Form" element={<EnrollForm/>}/>
        <Route path="/Enroll_Form1" element={<EnrollForm1/>}/> 
        <Route path="/Enroll_Form2" element={<EnrollForm2/>}/> 
        <Route path="/Enroll_Form3" element={<EnrollForm3/>}/> 
        <Route path="/Join_Cohort" element={<Join/>}/> 
        <Route path="/me/subscribers" element={<Subscribers/>}/> 
        <Route path="/me/messages" element={<Messages/>}/> 
        <Route path="/me/paid" element={<Paid/>}/>   
        <Route path="/Ebooks" element={<Ebook/>}/>   
      </Routes>
  </BrowserRouter>
  </>
    )
}

export default App;