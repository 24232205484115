import React, {useState} from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { PaystackButton } from "react-paystack";
import {useNavigate, BrowserRouter, Routes, Route} from 'react-router-dom';



const EnrollForm1 = () => {

  const publicKey = "pk_live_f777326ac8f811f57e14bc20e13b0a0fe41450af"
  const amount = 5000000
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const navigate = useNavigate()
  const generateRandomString = () => Math.random().toString(36).slice(2)

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Proceed",
    onSuccess: () => {
      setEmail("")
      setName("")
      setPhone("")
      navigate('/Join_Cohort');
    },
    onClose: () => alert("Wait! You need to Enroll! Don't miss out on this!!!"),
  }

	return(
		<>
		<header>
			<Navigation/>
		</header>
		<div class="payment-form">
			<div class="payment-form2">
				<h2 style={{textAlign:'center'}}>Payment Form</h2>
				<label>Full Name</label><br/>
				<input type="text" placeholder="Enter FullName" id="name" onChange={(e)=>setName(e.target.value)}/><br/>
				<label>Email</label><br/>
				<input type="email" placeholder="Enter your Email" id="email" onChange={(e)=>setEmail(e.target.value)}/><br/>
				<label>Whatsapp Phone Number</label><br/>
				<input type="tel" placeholder="Enter your Whatsapp Phone Number" id="phone" onChange={(e)=>setPhone(e.target.value)} /><br/>
				<p>You are about to make a purchase of NGN 50,000</p>
				<PaystackButton className="paystack-button" {...componentProps} />
			</div>
		</div>
		<Footer/>
		</>
		)
}

export default EnrollForm1;