import React from "react";
import data from './data/info';
import Navigation from './Navigation';
import {Link} from 'react-router-dom';

const Header = () => {
	return(
      <header>
        <Navigation />
          <div className="header-div">
            <h1>
              Learn at your <br/><span>convenience</span>
            </h1>
            <p>
              The Best learning platform to acquire a skill via a mentor, track your knowledge<br/> and hit your financial dream with your skills
            </p>
            <Link to="/#courses" onClick={()=>{window.location.href = "/#courses"}}><button>Get Started</button></Link>
          </div>
      </header>

		)
}
export default Header;