import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';


const FrontendCurriculum = () => {
	return(
		<>
		<header>
			<Navigation/>
		</header>
<div class="curriculum">
	<h4>1st Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>1st week</h5>
				<ul>
					<li>HTML Introduction</li>
					<li>HTML Editors</li>
					<li>HTML Basic</li>
					<li>HTML Elements</li>
					<li>HTML Attributes</li>
					<li>HTML Headings</li>
					<li>HTML Paragraphs</li>
					<li>HTML Styles</li>
					<li>HTML Formatting</li>
					<li>HTML Quotations</li>
					<li>HTML Comments</li>
					<li>HTML Introduction</li>
					<li>HTML Colors</li>
					<li>HTML CSS</li>
					<li>HTML Links</li>
					<li>HTML Images</li>
					<li>HTML Favicon</li>
					<li>HTML Tables</li>
					<li>HTML Lists</li>
					<li>HTML Block & Inline</li>
					<li>HTML Classes</li>
					<li>HTML Id</li>
					<li>HTML Iframes</li>
					<li>HTML JavaScript</li>
					<li>HTML File Paths</li>
					<li>HTML Head</li>
				</ul>
			</div>
			<div class="box_curr"><h5>2nd week</h5>
			<ul>
					<li>HTML Layout</li>
					<li>HTML Responsive</li>
					<li>HTML Computercode</li>
					<li>HTML Elements</li>
					<li>HTML Semantics</li>
					<li>HTML Entities</li>
					<li>HTML Symbols</li>
					<li>HTML Styles</li>
					<li>HTML Emojis</li>
					<li>HTML Quotations</li>
					<li>HTML Charset</li>
					<li>HTML URL Encode</li>
					<li>HTML vs. XHTML</li>
					<li>HTML Forms</li>
					<li>HTML Form Attributes</li>
					<li>HTML Form Elements</li>
					<li>HTML Input Types</li>
					<li>HTML Input Attributes</li>
					<li>HTML Input Form Attributes</li>
					<li>HTML Media</li>
					<li>HTML Video</li>
					<li>HTML Audio</li>
					<li>HTML Plug-ins</li>
					<li>HTML JavaScript</li>
					<li>HTML File Paths</li>
					<li>HTML Geolocation</li>
					<li>HTML Drag/Drop</li>
					<li>HTML Web Storage</li>
					<li>HTML Web Workers</li>
					<li>HTML SSE</li>
					<li>HTML Tag List</li>
					<li>HTML Attributes</li>
					<li>HTML Global Attributes</li>
					<li>HTML Events</li>
					<li>HTML Colors</li>
					<li>HTML Canvas</li>
					<li>HTML Doctypes</li>
					<li>HTML Character Sets</li>
					<li>HTML URL Encode</li>
					<li>HTML Lang Codes</li>
					<li>HTTP Messages</li>
					<li>HTTP Methods</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
				<ul>
					<li>CSS Introduction</li>
					<li>CSS Syntax</li>
					<li>CSS Selectors</li>
					<li>CSS How To</li>
					<li>CSS Comments</li>
					<li>CSS Colors</li>
					<li>CSS Backgrounds</li>
					<li>CSS Borders</li>
					<li>CSS Margins</li>
					<li>CSS Padding</li>
					<li>CSS Height/Width</li>
					<li>CSS Box Model</li>
					<li>CSS Outline</li>
					<li>CSS Text</li>
					<li>CSS Fonts</li>
					<li>CSS Icons</li>
					<li>CSS Links</li>
					<li>CSS Lists</li>
					<li>CSS Tables</li>
					<li>CSS Display</li>
					<li>CSS Max-width</li>
					<li>CSS Position</li>
					<li>CSS Z-index</li>
					<li>CSS Overflow</li>
					<li>CSS Float</li>
					<li>CSS Inline-block</li>
					<li>CSS Align</li>
					<li>CSS Combinators</li>
					<li>CSS Pseudo-class</li>
					<li>CSS Pseudo-element</li>
					<li>CSS Opacity</li>
					<li>CSS Navigation Bar</li>
					<li>CSS Dropdowns</li>
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
					<li>CSS Image Gallery</li>
					<li>CSS Image Sprites</li>
					<li>CSS Attr Selectors</li>
					<li>CSS Forms</li>
					<li>CSS Counters</li>
					<li>CSS Website Layout</li>
					<li>CSS Image Gallery</li>
					<li>CSS Units</li>
					<li>CSS Specificity</li>
					<li>CSS !important</li>
					<li>CSS Math Functions</li>
					<li>CSS Rounded Corners</li>
					<li>CSS Border Images</li>
					<li>CSS Backgrounds</li>
					<li>CSS Colors</li>
					<li>CSS Color Keywords</li>
					<li>CSS Gradients</li>
					<li>CSS Shadows</li>
					<li>CSS Text Effects</li>
					<li>CSS Web Fonts</li>
					<li>CSS 2D Transforms</li>
					<li>CSS 3D Transforms</li>
					<li>CSS Transitions</li>
					<li>CSS Animations</li>
					<li>CSS Tooltips</li>
				</ul>
			</div>
		</div>
	</div>
	<h4>2nd Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>1st week</h5>
				<ul>
					<li>CSS Style Images</li>
					<li>CSS Image Reflection</li>
					<li>HTML Basic</li>
					<li>CSS object-fit</li>
					<li>CSS object-position</li>
					<li>CSS Masking</li>
					<li>CSS Buttons</li>
					<li>CSS Pagination</li>
					<li>CSS Multiple Columns</li>
					<li>CSS User Interface</li>
					<li>CSS Variables</li>
					<li>CSS Variables</li>
					<li>CSS Box Sizing</li>
					<li>CSS Media Queries</li>
					<li>CSS MQ Examples</li>
					<li>CSS Flexbox</li>
					<li>RWD Intro</li>
					<li>RWD Viewport</li>
					<li>RWD Grid View</li>
					<li>RWD Media Queries</li>
					<li>RWD Images</li>
					<li>RWD Videos</li>
					<li>RWD Frameworks</li>
					<li>RWD Templates</li>
					<li>Grid Intro</li>
					<li>Grid Container</li>
					<li>Grid Item</li>
				</ul>
			</div>
			<div class="box_curr"><h5>2nd week</h5>
				<ul>
					<li>JS Introduction</li>
					<li>JS Where To</li>
					<li>JS Output</li>
					<li>JS Statements</li>
					<li>JS Syntax</li>
					<li>JS Comments</li>
					<li>JS Variables</li>
					<li>JS Let</li>
					<li>JS Const</li>
					<li>JS Operators</li>
					<li>JS Arithmetic</li>
					<li>JS Assignment</li>
					<li>JS Data Types</li>
					<li>JS Functions</li>
					<li>JS Events</li>
					<li>JS Strings</li>
					<li>JS String Methods</li>
					<li>JS String Search</li>
					<li>JS String Templates</li>
					<li>JS Numbers</li>
					<li>JS Number Methods</li>
					<li>JS Array Methods</li>
					<li>JS Array Sort</li>
					<li>JS Array Iteration</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
				<ul>
					<li>JS Array Const</li>
					<li>JS Dates</li>
					<li>JS Date Formats</li>
					<li>JS Date Get Methods</li>
					<li>JS Date Set Methods</li>
					<li>JS Random</li>
					<li>JS Booleans</li>
					<li>JS Comparisons</li>
					<li>JS If Else</li>
					<li>JS Switch</li>
					<li>JS Loop For</li>
					<li>JS Loop For In</li>
					<li>JS Loop For Of</li>
					<li>JS Loop While</li>
					<li>JS Break</li>
					<li>JS Iterables</li>
					<li>JS Sets</li>
					<li>JS Maps</li>
					<li>JS Typeof</li>
					<li>JS Bitwise</li>
					<li>JS RegExp</li>
					<li>JS Errors</li>
					<li>JS Scope</li>
					<li>JS Hoisting</li>
					<li>JS Strict Mode</li>		
					<li>JS this Keyword</li>
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
					<li>JS Arrow Function</li>
					<li>JS Classes</li>
					<li>JS Modules</li>
					<li>JS JSON</li>
					<li>JS Debugging</li>
					<li>JS Style Guide</li>
					<li>JS Best Practices</li>
					<li>JS Mistakes</li>
					<li>JS Performance</li>
					<li>JS Reserved Words</li>
					<li>Object Definitions</li>
					<li>Object Properties</li>
					<li>Object Methods</li>
					<li>Object Display</li>
					<li>Object Accessors</li>
					<li>Object Constructors</li>
					<li>Object Prototypes</li>
					<li>Object Iterables</li>
					<li>Object Sets</li>
					<li>Object Maps</li>
					<li>Function Definitions</li>
					<li>Function Parameters</li>
					<li>Function Invocation</li>
					<li>Function Call</li>
					<li>Function Apply</li>
					<li>Function Bind</li>
					<li>Function Closures</li>
					<li>Class Intro</li>
					<li>Class Inheritance</li>
					<li>Class Static</li>
					<li>JS Async</li>
					<li>JS Callbacks</li>
					<li>JS Asynchronous</li>
					<li>JS Promises</li>
					<li>JS Async/Await</li>
				</ul>
			</div>
		</div>
	</div>
	<h4>3rd Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>1st week</h5>
				<ul>
					<li>DOM Intro</li>
					<li>DOM Methods</li>
					<li>DOM Document</li>
					<li>DOM Elements</li>
					<li>DOM HTML</li>
					<li>DOM Forms</li>
					<li>DOM CSS</li>
					<li>DOM Animations</li>
					<li>DOM Events</li>
					<li>DOM Event Listener</li>
					<li>DOM Navigation</li>
					<li>DOM Nodes</li>
					<li>DOM Collections</li>
					<li>DOM Node Lists</li>
					<li>JS Window</li>
					<li>JS Screen</li>
					<li>JS Screen</li>
					<li>JS Location</li>
					<li>JS History</li>
					<li>JS Navigator</li>
					<li>JS Popup Alert</li>
					<li>JS Timing</li>
					<li>JS Cookies</li>
					<li>Web API Intro</li>
					<li>Web Forms API</li>
					<li>Web History API</li>
					<li>Web Storage API</li>
					<li>Web Worker API</li>
					<li>Web Fetch API</li>
					<li>Web Geolocation API</li>	
				</ul>
			</div>
			<div class="box_curr"><h5>2nd week</h5>
				<ul>
					<li>AJAX Intro</li>
					<li>AJAX XMLHttp</li>
					<li>AJAX Request</li>
					<li>AJAX Response</li>
					<li>AJAX XML File</li>
					<li>AJAX PHP</li>
					<li>AJAX ASP</li>
					<li>AJAX Database</li>
					<li>AJAX Applications</li>
					<li>AJAX Examples</li>
					<li>JSON Intro</li>
					<li>JSON Syntax</li>
					<li>JSON vs XML</li>
					<li>JSON Stringify</li>
					<li>JSON Objects</li>
					<li>JSON Arrays</li>
					<li>JSON Server</li>
					<li>JSON PHP</li>
					<li>JSON HTML</li>
					<li>JSON JSONP</li>
					<li>JS version(ES6)</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
				<ul>
					<li>React Intro</li>
					<li>React Get Started</li>
					<li>React ES6</li>
					<li>React Render HTML</li>
					<li>React JSX</li>
					<li>React Components</li>
					<li>React Class</li>
					<li>React Props</li>
					<li>React Events</li>
					<li>React Conditionals</li>
					<li>React Lists</li>
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
					<li>React Forms</li>
					<li>React Router</li>
					<li>React Memo</li>
					<li>React CSS Styling</li>
					<li>React Sass Styling</li>
					<li>What is a Hook?s</li>
					<li>useState</li>
					<li>useEffect</li>
					<li>useContext</li>
					<li>useRef</li>
					<li>useReducer</li>
					<li>useCallback</li>
					<li>useMemo</li>
					<li>Custom Hooks</li>		
				</ul>
			</div>
		</div>
	</div>
</div>
		<Footer/>
		</>
		)
}

export default FrontendCurriculum;