import React, {useState, useEffect} from "react";
import data from './data/info';


const Testimony = () => {

  var t = [
          "Going through JBUIT has given me confidence in the creation of relative programs and building my own portfolio as a developer. Learning is very flexible and I have gained skills which I can apply in freelancing / front-end development jobs. ",
          "JBUIT has experienced tutors that can help you scale from basic to advanced in a short time"
          ];
  var i = [
          "./images/img2.jpeg",
          "./images/tester.jpeg"
          ];
  var n = [
          "Dada Jennifer",
          "Chizaram Chigoziem"
          ];
  var e = [
          "Nigeria",
          "Nigeria"
          ];

  var c = ["black"];


  const [count, setCount] = useState(0);
  const [text, setText] = useState(t[count]);
  const [image, setImage] = useState(i[count]);
  const [name, setName] = useState(n[count]);
  const [country, setCountry] = useState(e[count]);
  const [color, setColor] = useState("rgb(65,105,225)");
  const [colo, setColo] = useState("rgb(65,105,225)");


  useEffect(()=>{
    const timer = setTimeout(()=>{
      setCount((count)=>(count+1) % t.length); 
      setText(t[count]); 
      setImage(i[count]);
      setName(n[count]);
      setCountry(e[count]); 

    } ,5000);
  
        if(text==t[0]){
            setColor('black');
            setColo("rgb(65,105,225)");
         
        }
        if(text==t[1]){
            setColor("rgb(65,105,225)");
            setColo('black');
            
        }
        if(text==t[2]){
            setColor("rgb(65,105,225)");
            setColo("rgb(65,105,225)");
            
        }
    return()=>{
    clearTimeout(timer);
    }
  });
	return(
  <>
	<div className="testimonials" id="testimonial">
      <h3 style={{textAlign: "center"}}>What Our Students Say</h3>
      <div className="testimony-cover row">
        <div className="testimony row">
          <div className="test column" style={{alignItems: "center", height:"auto",padding:"10px"}}>
              <img src={image} style={{height: "150px", width: "150px", borderRadius: "50%"}}/>
              <div style={{textAlign: "center"}}>
                <h3>{name}</h3>
                <p>"{text}"</p>
                <h3>{country}</h3>
              </div>
          </div>
        </div>
        <div className="carousel row">
          <div style={{backgroundColor: color}}></div>
          <div style={{backgroundColor: colo}}></div>

        </div>
      </div>
    </div>
    </>
		)
}

export default Testimony;