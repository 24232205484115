import React from 'react';
import data from './data/info';
import Enroll from './Enroll';
import {Link} from 'react-router-dom';

const Course = () => {
	return(
<>
	<h3 style={{textAlign: "center"}}>Courses</h3>
	<div className="card row">
	 {data.courses.map((course)=>(
	  <div className="card-cover row">
        <div className="card-details">
          <h2>{course.stack}</h2>
          <ul>
            {course.languages.map((language)=>(<li>{language}</li>))}
          </ul>
          <div className="div" >{course.Curriculum}</div>
          <Link to={course.curriculum_link}><button style={{marginLeft: "40px"}}>See Curriculum</button></Link>
          {/*<Link to={course.tutorlink}><button>About Tutor</button></Link>*/}
        </div>
        <div className="card-description">
          <img src={course.images}/>
          <div>
            <h2>Description</h2>
            <span></span>
            <h4>Duration:  {course.duration}<span></span></h4>
            <h1>Fee:  {course.price}</h1>
            <Link to={course.enroll}><button>Enroll Now</button></Link>
          </div>
        </div>
    </div>))}
  </div>
</>

		)
}

export default Course;