import React,{useState} from "react";
import axios from 'axios';
import data from './data/info';


const Footer = () => {
	const [data, setData] = useState({
		name:"",
		email:"",
		message:""
	});
	const [display, setDisplay] = useState("none");

	const handleChange = (e)=>{
		setData({...data, [e.target.name]: e.target.value});
		console.log(data);
	}
	const handleSubmit = (e) => {
	  e.preventDefault();
	  const sendData = {
	  	name: data.name,
	  	email: data.email,
	  	message: data.message
	  }

	  axios.post("https://jbuit.com/api/contact/",sendData)
	  .then((result)=>{
	  	if(result.data.status == "invalid"){
	  		alert("we have an issue")
	  	}else{
	  		setDisplay("block")
	  	}
	  })
	  console.log(sendData);
	  } 

	return(
<>
	<div className="contact-form">
		<h3 style={{color: "#fff", textAlign: "center"}}>Message Us Directly</h3>
		<p style={{color:"white", display:display, textAlign:"center"}}>Thanks for your Message, we will get back to you via your mail address</p>
		<form className="row footer-form" method="post">
			<input type="text" name="name" placeholder="Enter your Name" onChange={handleChange} value={data.name}/>
			<input type="email" name="email" placeholder="Enter your Email" onChange={handleChange}/>
			<textarea placeholder="Text your Message" name="message" onChange={handleChange}></textarea>
			<button type="submit" form="form" onClick={handleSubmit}>Send</button>
		</form>
	</div>
	  <footer className="row">
	    <h3>JBUIT © 2022 </h3>
	    <div className="media row">
	      <p>Follow us on our social media platforms</p>
	      <span className="media-pt row">
	        <a href="https://twitter.com/officialjbuit"><i className="fa fa-twitter fa-1x"></i></a>
	        <a href="https://www.instagram.com/official_jbuit/"><i className="fa fa-instagram fa-1x"></i></a>
	        <a href="https://web.facebook.com/Jbuitofficial/"><i className="fa fa-facebook fa-1x"></i></a>
	        <a href="https://www.linkedin.com/company/jbuit-tech-company/about/"><i className="fa fa-linkedin fa-1x"></i></a>
	      </span>
	    </div>
	  </footer>
</>
		)
}

export default Footer;