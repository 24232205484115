import React from 'react';


const Paid = () =>{
	return(
		<>
		Paid
		</>
		)
}
export default Paid;