import React, {useState} from "react";
import data from './data/info';
import {Link, Outlet} from "react-router-dom";

const Navigation = () => {
  const [display, setDisplay] = useState('none');
  const [position, setPosition] = useState('relative');
	return(
        <>
        	<nav style={{position: position}}>
            <Link to="/" style={{color: "white", textDecoration: "none"}}>
              <span className="logo">
                <img src="./images/logo.jpeg"/>
                <h1>JBUIT</h1>
              </span>
            </Link>
              <ul>
                {/*
                                <li><Link to="../pages/Ebooks" onClick={()=>{window.location.href = "/Ebooks"}}>Ebooks</Link></li>
                */}
                <li><Link to="/#courses" onClick={()=>{window.location.href = "/#courses"}}>Courses</Link></li>
                <li><Link to="/#testimonial" onClick={()=>{window.location.href = "/#testimonial"}}>Testimonial</Link></li>
                <li><Link to="/#faq" onClick={()=>{window.location.href = "/#faq"}}>F.A.Q</Link></li>
                <li><Link to="/#contact" onClick={()=>{window.location.href = "/#contact"}}>Contact</Link></li>
                <li><Link to="/#courses" onClick={()=>{window.location.href = "/#courses"}}><button>Get Started</button></Link></li>
              </ul>
              <div onClick={()=>{setDisplay('block'); setPosition('fixed');}}>
                <i className="fa fa-bars fa-2x"></i>
              </div>
          </nav>
          <div className="menu-cover" style={{display: display}}>
              <div onClick={()=>{setDisplay('none'); setPosition('relative');}} style={{float: "right", color: "black", marginTop:"10px", marginRight: "10px", cursor: "pointer"}}>
                <i className="fa fa-times fa-2x"></i>
              </div>
              <ul>
                <li><Link to="/#courses" onClick={()=>{window.location.href = "/#courses"; setDisplay('none'); setPosition('relative');}} style={{textDecoration: "none", color: "black"}}>Courses</Link></li>
                <li><Link to="/#testimonial" onClick={()=>{window.location.href = "/#testimonial"; setDisplay('none'); setPosition('relative');}} style={{textDecoration: "none", color: "black"}}>Testimonial</Link></li>
                <li><Link to="/#faq" onClick={()=>{window.location.href = "/#faq"; setDisplay('none'); setPosition('relative');}} style={{textDecoration: "none", color: "black"}}>F.A.Q</Link></li>
                <li><Link to="/#contact" onClick={()=>{window.location.href = "/#contact"; setDisplay('none'); setPosition('relative');}} style={{textDecoration: "none", color: "black"}}>Contact</Link></li>
                <li><Link to="/#courses" onClick={()=>{window.location.href = "/#courses"; setDisplay('none'); setPosition('relative');}}><button>Get Started</button></Link></li>
              </ul>
          </div>
        </>
		)
}
export default Navigation;