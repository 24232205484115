import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const BackendCurriculum = () => {
	return(
		<>
		<header>
			<Navigation/>
		</header>
<div class="curriculum">
	<h4>1st Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>1st week</h5>
				<ul>
					<li>Node.js Intro</li>
					<li>Node.js Get Started</li>
					<li>Node.js Modules</li>
					<li>Node.js HTTP Module</li>
					<li>Node.js File System</li>
				</ul>
			</div>
			<div class="box_curr"><h5>2nd week</h5>
			<ul>
				    <li>Node.js URL Module</li>
					<li>Node.js NPM</li>
					<li>Node.js Events</li>
					<li>Node.js Upload Files</li>
					<li>Node.js Email</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
			<ul>
				    <li>Node.js MySQL</li>
					<li>MySQL Get Started</li>
					<li>MySQL Create Database</li>
					<li>MySQL Create Table</li>
					<li>MySQL Insert Into</li>
					<li>MySQL Select From</li>
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
					<li>MySQL Where</li>
					<li>MySQL Order By</li>
					<li>MySQL Delete</li>
					<li>MySQL Drop Table</li>
					<li>MySQL Update</li>
					<li>MySQL Limit</li>
					<li>MySQL Join</li>
				</ul>
			</div>
		</div>
	</div>
	<h4>2nd Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>1st week</h5>
			<ul>
				    <li>Node.js MongoDB</li>
					<li>MongoDB Get Started</li>
					<li>MongoDB Create Database</li>
					<li>MongoDB Create Collection</li>
					<li>MongoDB Insert</li>
					<li>MongoDB Find</li>
				</ul>
			</div>
			<div class="box_curr"><h5>2nd week</h5>
				<ul>
					<li>MongoDB Query</li>
					<li>MongoDB Sort</li>
					<li>MongoDB Delete</li>
					<li>MongoDB Drop Collection</li>
					<li>MongoDB Update</li>
					<li>MongoDB Limit</li>
					<li>MongoDB Join</li>
					<li>Built-in Modules</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
				<ul>
	                <li>Introduction To Express js</li>
					<li>Express.js Overview</li>
					<li>Our first Express Application</li>
					<li>Routing</li>
					<li>Route parameters and queries</li>
					<li>Configurations</li>
					<li>Enhancing apps with middleware</li>
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
	                <li>Working with Data</li>
					<li>Displaying Data in Views</li>
					<li>Working with Forms</li>
					<li>Uploading Files</li>
					<li>Serving Files</li>
					<li>Locals and Helpers</li>
				</ul>
			</div>
		</div>
	</div>
	<h4>3rd Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>1st week</h5>
				<ul>
					<li>Working with cookies</li>
					<li>Working with sessions</li>
					<li>Custom middleware</li>
					<li>Authentication and Authorization</li>
					<li>Error Handling</li>
					<li>Using express to create web apps</li>
				</ul>
			</div>
		</div>
	</div>
</div>
		<Footer/>
		</>
		)
}

export default BackendCurriculum;