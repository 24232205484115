import React from 'react';
import data from '../components/data/info';
import Course from '../components/Course';
import Benefits from '../components/Benefits';
import Services from '../components/Services';
import Program from '../components/Program';
import Testimony from '../components/Testimony';
import Contact from '../components/Contact';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomizedAccordion from '../components/CustomizedAccordion';


function Home(){
	return(
	<>
      <header>
        <Header />
      </header>
	    <main id="courses">
        <Course />
      </main>
      <div className="tees row">
        <div style={{textAlign:"center"}}>
          <h3>Get A Free JBUIT Tee</h3>
          <p>Enroll Now and get one of JBUIT Tees delivered to your doorstep</p>
        </div>
        <img src="./images/tee.png" alt="jbuit tees"/>
      </div>
      <section>
        <Benefits />
        <Program />
        <Testimony />
        <Services />
        <div className="accordion" id="faq">
          <h3>Frequently Asked Questions (F.A.Q)</h3>
          <span>Here are some of the questions you might be curious to ask</span>
          <CustomizedAccordion/>
        </div>
        <Contact />
      </section>
      <Footer/>
  </>
		)
}
export default Home;