import React, {useState} from "react";
import data from './data/info';
import {Link} from 'react-router-dom';
import axios from 'axios';


const Contact = () => {
  const [display, setDisplay] = useState("none");
  const [data, setData] = useState({
    subscriber:""
  });

  const handleChange = (e)=>{
    setData({...data, [e.target.name]: e.target.value});
    console.log(data);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const sendData = {
      subscriber: data.subscriber
    }

    axios.post("https://jbuit.com/api/contact/",sendData)
    .then((result)=>{
      if(result.data.status == "invalid"){
        alert("we have an issue")
      }else{
        setDisplay("block")
      }
    })
    console.log(sendData);
    } 

 

	return(
	<>
    <div className="contact" id="contact">
      <h3>To Send Us A Message</h3>
      <p>Chat us on Whatsapp by clicking on the whatsapp icon below</p>
      <Link to="#whatsapp" onClick={()=>{window.location.href = 'https://wa.me/+2348123888508'}}><i className="fa fa-whatsapp fa-4x"></i></Link>
    </div>
    <div className="subscribe">
      <h3>Subscribe to our newsletter to recieve weekly <br/>updates from us on our trainings, services and tech related news</h3>
      <form action="#">
        <input type="email" name="subscriber" placeholder="youremail@domain.com" onChange={handleChange} value={data.subscriber}/>
        <button type="submit" form="form" onClick={handleSubmit}>Subscribe</button>
        <p style={{display:display, textAlign:"center", color: "rgb(0,0,128)"}}>Thanks for subscribing</p>
      </form>
    </div>
    </>
		)
}

export default Contact;