import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const DataCurriculum = () => {
	return(
		<>
		<header>
			<Navigation/>
		</header>
<div class="curriculum">
	<h4>1st Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>first week</h5>
				<ul>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
			<div class="box_curr"><h5>2 week</h5>
			<ul>
				    <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
			<ul>
				    <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>

				</ul>
			</div>
		</div>
	</div>
	<h4>2nd Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>first week</h5>
			<ul>
				    <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>

				</ul>
			</div>
			<div class="box_curr"><h5>2 week</h5>
				<ul>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
				<ul>
	                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
	                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
		</div>
	</div>
	<h4>3rd Month</h4>
	<div class="main_curr">
		<div class="cover_curr">
			<div class="box_curr"><h5>first week</h5>
				<ul>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
			<div class="box_curr"><h5>2 week</h5>
				<ul>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
				</ul>
			</div>
			<div class="box_curr"><h5>3rd week</h5>
				<ul>
				    <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					
				
				</ul>
			</div>
			<div class="box_curr"><h5>4th week</h5>
				<ul>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					<li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
					
				</ul>
			</div>
		</div>
	</div>
</div>
		<Footer/>
		</>

		)
}

export default DataCurriculum;