import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  fontFamily: "'Montserrat', sans-serif",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="acc">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>WHY SHOULD I ENROLL INTO JBUIT?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>
           You are trained by professional tutors
           <br/>
           Conducive learning environment
           <br/>
           A strong community of developers
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>HOW LONG DOES EACH COURSE TAKE?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>
                It's specified in the course details card above
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>WHAT IS THE MODE OF TRAINING?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>
                  It's a physical training, and you will be taught at the institute
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>WILL I GET A CERTIFICATE IF I COMPLETE THE THREE MONTHS PROGRAMME?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>
              Yes, you will be given a certificate after you have completed your programme.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>WILL I GET A PROJECTS/JOB AFTER THE TRAINING?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>
            You will be taught on how to handle real life projects, how to get them and also how to secure a job in the tech space.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>WHAT DAYS WILL THE TRAINING HOLD?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>
            Three days in a week; depending on your availability
            <br/>
            Monday - Thurday, Saturday (Weekend Classes)
            <br/>
            Morning Session: 11am - 1:30pm
            <br/>
            Afternoon Session: 1:30pm - 4:00pm 
          </Typography>
        </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>HOW MUCH DO YOU OFFER YOUR SERVICES?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Montserrat', sans-serif"}}>
             Prices vary depending on the complexity of the project
             <h5>For web development:</h5>
             We Charge #300,000 and above for quality website delivery
             <br/>
             <h5>For Mobile App development:</h5>
             We charge #700,000 and above for quality app delivery
             <br/>          
             <h5>For Data Science projects:</h5>
             We charge #300,000 and above
             <br/>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
